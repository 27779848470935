import { screenSizes } from './screen-sizes.theme';

const desktop = {
  h1: '76px',
  h2: '40px',
  t1: '22px',
  t2: '18px',
  b: '20px',
  default: '1rem',
};

const mobile = {
  h1: '52px',
  h2: '28px',
  t1: '20px',
  t2: '16px',
  b: '16px',
  default: '1rem',
};

export const fontSizes = window.innerWidth <= screenSizes.md ? mobile : desktop;
