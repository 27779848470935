import {
  Box,
  Button,
  CircularProgress,
  Input,
  Snackbar,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { BlackButton } from 'assets/components/black-button.styled';
import { Anchor } from 'modules/common/enums';
import { useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import emailjs from 'emailjs-com';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const ContactUs = () => {
  const { t } = useTranslation();
  const [isUploadedResume, setUploadedResume] = useState<boolean>(false);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t(translations.contactUs.validation.nameRequired)),
    telegram: yup
      .string()
      .required(t(translations.contactUs.validation.telegramRequired)),
    message: yup
      .string()
      .required(t(translations.contactUs.validation.coverLetterRequired)),
    resume: yup
      .mixed<File>()
      .test(
        'invalidFileType',
        t(translations.contactUs.validation.resumeRequired),
        (value) => (value && value?.type === 'application/pdf' ? true : false)
      )
      .required(t(translations.contactUs.validation.resumeRequired)),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      telegram: '',
      message: '',
      resume: {} as File,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('resume', e.target.files?.[0] as File);
    setUploadedResume(true);
  };

  const handleApply = async (data: {
    name: string;
    telegram: string;
    message: string;
    resume: File;
  }) => {
    try {
      setIsLoading(true);
      const response = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_TEMPLATEID!,
        data,
        process.env.REACT_APP_EMAILJS_USERID!
      );
      setSnackbarMessage(response.text);
      setIsError(false);
      setOpen(true);
    } catch (error) {
      setIsError(true);
      setSnackbarMessage('Something went wrong');
      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content} id={Anchor.CONTACT_US}>
        <Typography sx={styles.title}>
          {t(translations.contactUs.title)}
        </Typography>
        <Typography sx={styles.description}>
          {t(translations.contactUs.description)}
        </Typography>
      </Box>

      <Box
        component="form"
        sx={styles.form}
        onSubmit={handleSubmit(handleApply)}
      >
        <Stack width="100%">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t(translations.contactUs.placeholers.name)}
                disableUnderline
                sx={styles.input}
              />
            )}
          />
          {errors.name && (
            <Typography sx={styles.error}>{errors.name?.message}</Typography>
          )}
        </Stack>
        <Stack width="100%">
          <Controller
            name="telegram"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t(translations.contactUs.placeholers.telegram)}
                disableUnderline
                sx={styles.input}
              />
            )}
          />
          {errors.telegram && (
            <Typography sx={styles.error}>
              {errors.telegram?.message}
            </Typography>
          )}
        </Stack>
        <Stack width="100%">
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                className="constact-us-textarea"
                style={styles.bigInput as React.CSSProperties}
                placeholder={t(translations.contactUs.placeholers.coverLetter)}
                minRows={6}
                maxRows={6}
              />
            )}
          />
          {errors.message && (
            <Typography sx={styles.error}>{errors.message?.message}</Typography>
          )}
        </Stack>

        <Stack width="100%">
          <Box sx={styles.buttons}>
            <input
              id="button-file"
              accept=".pdf"
              style={{ display: 'none' }}
              type="file"
              {...register('resume', { onChange })}
            />
            <Button sx={styles.inputFile}>
              <Typography component="label" htmlFor="button-file">
                {isUploadedResume
                  ? t(translations.contactUs.uploaded)
                  : t(translations.contactUs.addResume)}
              </Typography>
            </Button>

            <BlackButton sx={styles.button} type="submit">
              {isLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                t(translations.contactUs.build)
              )}
            </BlackButton>
          </Box>
          {errors.resume && (
            <Typography sx={styles.error}>{errors.resume?.message}</Typography>
          )}
        </Stack>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={() => setOpen(false)}
        sx={styles.snackbar}
      >
        <MuiAlert severity={isError ? 'error' : 'success'}>
          <Typography>{snackbarMessage}</Typography>
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};
