import { Stack, Typography } from '@mui/material';
import { useColorScheme } from 'hooks/use-color-scheme';
import { styles } from './styles';

export type CardProps = {
  title: string;
  description: string;
  imgWhite: string;
  imgBlack: string;
  color: string;
};

export const Card = ({
  title,
  description,
  imgWhite,
  imgBlack,
  color,
}: CardProps) => {
  const colorScheme = useColorScheme();
  return (
    <Stack
      sx={{
        border: `2px solid ${color}`,
        borderRadius: '8px',
        boxSizing: 'border-box',
      }}
      alignItems="center"
      rowGap="10px"
      padding="20px"
      justifyContent={'center'}
      height={'100%'}
    >
      <img
        src={colorScheme ? imgBlack : imgWhite}
        alt=""
        height={50}
        width={50}
      />
      <Typography sx={styles.title}>{title}</Typography>
      <Typography sx={styles.description}>{description}</Typography>
    </Stack>
  );
};
