import { Button, Typography } from '@mui/material';
import styled from 'styled-components';
import { theme } from 'theme';

export type AnchorButtonProps = {
  text: string;
  onClick?(): void;
  variant: 'black' | 'white';
};

export const ButtonStyled = styled(Button)`
  text-decoration: none !important;
  font-size: ${theme.fontSizes.default} !important;
  cursor: pointer;
  position: relative;
  text-transform: none !important;
  padding: 0;

  &:hover {
    background-color: transparent !important;
  }

  p {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      bottom: 0px;
      left: 0;
      transform-origin: bottom right;
      transition: transform 0.3s ease-out;
    }

    @media (hover: hover) {
      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
`;

export const AnchorButton = ({ text, onClick, variant }: AnchorButtonProps) => (
  <ButtonStyled onClick={onClick}>
    <Typography
      color={variant === 'black' ? theme.colors.dark : theme.colors.white}
      sx={{
        '&::after': {
          backgroundColor:
            variant === 'black' ? theme.colors.dark : theme.colors.white,
        },
      }}
      align="center"
    >
      {text}
    </Typography>
  </ButtonStyled>
);
