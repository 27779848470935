import { useEffect, useState } from "react";

/**
 * Custom hook that detects the user's preferred color scheme (dark or light) and returns a boolean value indicating whether dark mode is preferred.
 *
 * @return {boolean} A boolean value indicating whether dark mode is preferred by the user.
 */
export const useColorScheme = (): boolean => {
    const [prefersDarkMode, setPrefersDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setPrefersDarkMode(mediaQuery.matches);

    const handleChange = (event: MediaQueryListEvent) => {
      setPrefersDarkMode(event.matches);
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return prefersDarkMode;
};