import { styles } from './styles';
import { Stack, Typography } from '@mui/material';

export type CardProps = {
  title: string;
  description: string;
  img: string;
  color: string;
  gridRowStart: number;
  gridColumnStart: number;
  gridRowEnd: number;
  gridColumnEnd: number;
};

export const Card = ({
  title,
  description,
  img,
  color,
  gridRowStart,
  gridColumnStart,
  gridRowEnd,
  gridColumnEnd,
}: CardProps) => (
  <Stack
    sx={{
      border: `2px solid ${color}`,
      borderRadius: '8px',
      padding: '20px',
      gridRowStart,
      gridColumnStart,
      gridRowEnd,
      gridColumnEnd,
    }}
    alignItems="flex-start"
    rowGap="10px"
  >
    <img src={img} alt="" />
    <Typography sx={styles.title}>{title}</Typography>
    <Typography sx={styles.description}>{description}</Typography>
  </Stack>
);
