import { theme } from 'theme';
import bg from 'assets/svg/contact-us/bg.svg';

export const styles = {
  root: {
    display: 'flex',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '100px',
    borderRadius: '24px',
    justifyContent: 'space-around',
    gap: '100px',

    [theme.breakpoints.md]: {
      flexDirection: 'column',
      gap: '20px',
      padding: '20px',
    },
  },
  container: {
    display: 'flex',
  },
  content: {
    flexDirection: 'column',
    flex: '3',
    gap: '40px',
    display: 'flex',
  },
  title: {
    fontSize: theme.fontSizes.h2,

    [theme.breakpoints.md]: {
      textAlign: 'center',
    },
  },
  description: {
    fontSize: theme.fontSizes.t1,

    [theme.breakpoints.md]: {
      textAlign: 'center',
      fontSize: theme.fontSizes.t2,
    },
  },
  mail: {
    fontSize: theme.fontSizes.t1,
    position: 'relative',
    zIndex: '1',

    [theme.breakpoints.md]: {
      textAlign: 'center',
      fontSize: theme.fontSizes.t2,
    },
  },
  form: {
    flex: '2',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
  },
  input: {
    border: `1px solid ${theme.colors.lightGrey}`,
    backgroundColor: theme.colors.white,
    borderRadius: '8px',
    padding: '4px 10px',
    width: 'min(100%, 500px)',
    fontSize: theme.fontSizes.default,
    '@media (min-width: 1000px)': {
      width: '100%',
    },
    '::placeholder': {
      color: `${theme.colors.black} !important`,
    },
  },
  bigInput: {
    border: `1px solid ${theme.colors.lightGrey}`,
    backgroundColor: theme.colors.white,
    borderRadius: '8px',
    padding: '4px 10px',
    width: 'min(100%, 500px)',
    boxSizing: 'border-box',
    fontSize: theme.fontSizes.default,
    resize: 'none',
  },
  button: {
    backgroundColor: theme.colors.dark,
    borderRadius: '8px',
    height: '60px',
    minWidth: '50%',
    fontSize: 'clamp(0.9rem, 0.8vw, 1rem) !important',

    '&:hover': {
      backgroundColor: theme.colors.dark,
    },
  },
  email: {
    textDecoration: 'none',
    color: theme.colors.dark,
  },
  buttons: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'min(100%, 500px)',
  },
  inputFile: {
    borderRadius: '8px',
    border: `2px solid #000`,
    height: '100%',
    width: '100%',
    padding: '0',

    label: {
      cursor: 'pointer',
      height: '100%',
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      textTransform: 'none',
      width: '100%',
      transition: '0.3s ease',
      color: '#000',

      '&:hover': {
        backgroundColor: '#000',
        color: '#fff',
      },
    },
  },
  snackbar: {
    top: 'auto',
    bottom: '20px',
    width: 300,
    height: '50px',
  },
  error: {
    color: '#bf1650',
    '&::before': {
      content: "'⚠ '",
    },
  },
  fileButton: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
