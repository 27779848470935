export const styles = {
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(14, 1fr)',
        gap: '70px 25px',
        '@media (max-width: 800px)': {
            gridTemplateColumns: '1fr',
            gap: '20px 0', 
        }
    },
    title: {
        fontSize: 'clamp(2rem, 5vw, 6rem)',
        textAlign: 'center',
        '@media (prefers-color-scheme: dark)': {
            color: 'white',
        }
    },
    img: {
        gridRowStart: 1,
        gridColumnStart: 6,
        gridRowEnd: 4,
        gridColumnEnd: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}