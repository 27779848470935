import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { styles } from './styles';
import { Case } from './components/case';
import one from 'assets/svg/cases/one.svg';
import two from 'assets/svg/cases/two.svg';
import three from 'assets/svg/cases/three.svg';
import { Slider } from 'modules/common/slider/slider';
import { Anchor } from 'modules/common';

type Keys = keyof typeof translations.cases.items;

const props = [
  {
    color: '#2DCA8F',
    img: one,
  },
  {
    color: '#C1B5FF',
    img: two,
  },
  {
    color: '#2DCA8F',
    img: three,
  },
];

export const Cases = () => {
  const { t } = useTranslation();

  return (
    <Stack gap="40px">
      <Box sx={styles.text}>
        <Typography sx={styles.title} id={Anchor.CASES}>{t(translations.cases.title)}</Typography>
        <Typography sx={styles.description}>
          {t(translations.cases.description)}
        </Typography>
      </Box>
      <Slider
        list={props.map((item, index) => (
          <Case
            key={index}
            {...item}
            title={t(
              translations.cases.items[index.toString() as Keys].title
            )}
            description={t(
              translations.cases.items[index.toString() as Keys].description
            )}
          />
        ))}
      />
    </Stack>
  );
};
