import { Button } from '@mui/material';
import styled from 'styled-components';
import { theme } from 'theme';

export const BlackButton = styled(Button)`
  background-color: ${theme.colors.black} !important;
  color: ${theme.colors.white} !important;
  font-size: clamp(13.5px, 1vw, ${theme.fontSizes.default}) !important;
  text-transform: none !important;
  border-radius: 8px !important;
  border: 2px solid ${theme.colors.dark} !important;
  padding: 5px 25px !important;
  position: relative;
  z-index: 1;

  @media (hover: hover) {
    &:hover {
      background-color: transparent !important;
      color: ${theme.colors.dark} !important;
    }
  }

  @media (hover: none) {
    &:active {
      background-color: transparent !important;
      color: ${theme.colors.dark} !important;
    }
  }
`;
