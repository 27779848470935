export const styles = {
    title: {
        textAlign: 'center',
        fontSize: 'clamp(2rem, 5vw, 6rem)',
        '@media (prefers-color-scheme: dark)': {
            color: 'white',
        }
    },
    description: {
        textAlign: 'start',
        width: '50%',
        fontSize: 'clamp(1rem, 1.1vw, 1.2rem)',
        '@media (max-width: 800px)': {
            textAlign: 'center',
            width: '100%',
        },
        '@media (prefers-color-scheme: dark)': {
            color: 'white',
        }
    },
    text: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '@media (max-width: 800px)': {
            flexDirection: 'column',
        }
    },
}