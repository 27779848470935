import { Box, Stack, Typography } from '@mui/material';
import { styles } from './styles';
import { useColorScheme } from 'hooks/use-color-scheme';

export type StageProps = {
  title: string;
  description: string;
  imgWhite: string;
  imgBlack: string;
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number;
  border?: {
    top?: boolean;
    right?: boolean;
    left?: boolean;
    topLeft?: boolean;
    topRight?: boolean;
    bottomRight?: boolean;
    bottomLeft?: boolean;
    bottom?: boolean;
  };
};

export const Stage = ({
  title,
  imgWhite,
  border,
  imgBlack,
  description,
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  gridRowEnd,
}: StageProps) => {
  const colorScheme = useColorScheme();
  return (
    <Stack
      sx={{
        ...styles.stack,
        gridColumnStart,
        gridColumnEnd,
        gridRowStart,
        gridRowEnd,
        '&::after': {
          content: '""',
          position: 'absolute',
          height: 'calc(100% - 2px)',
          ...(border?.topLeft
            ? {
                width: '50%',
                top: 0,
                left: 0,
                borderTop: '2px solid #C1B5FF',
              }
            : {}),
          ...(border?.topRight
            ? {
                width: '50%',
                top: 0,
                right: 0,
                borderTop: '2px solid #C1B5FF',
              }
            : {}),
          ...(border?.top
            ? { width: '100%', inset: 0, borderTop: '2px solid #C1B5FF' }
            : {}),
          ...(border?.right
            ? {
                borderRight: '2px solid #C1B5FF',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              }
            : {}),
          ...(border?.left
            ? {
                borderLeft: '2px solid #C1B5FF',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }
            : {}),
          ...(border?.bottomRight || border?.bottomLeft || border?.bottom
            ? {
                borderBottom: '2px solid #C1B5FF',
              }
            : {}),
        },
      }}
      justifyContent="center"
      alignItems="center"
      gap="10px"
    >
      <Box sx={styles.img}>
        <img src={colorScheme ? imgBlack : imgWhite} alt="" />
      </Box>
      <Typography variant="h1" sx={styles.title}>
        {title}
      </Typography>
      <Typography sx={styles.subtitle}>{description}</Typography>
    </Stack>
  );
};
