import { Box, Typography, useMediaQuery } from '@mui/material';
import { Stage } from './stage';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import introductoryLight from 'assets/svg/services/introductory-light.svg';
import introductoryDark from 'assets/svg/services/introductory-dark.svg';
import imporvingLight from 'assets/svg/services/improving-skils-light.svg';
import imporvingDark from 'assets/svg/services/improving-skils-dark.svg';
import cvLight from 'assets/svg/services/cv-light.svg';
import cvDark from 'assets/svg/services/cv-dark.svg';
import workLight from 'assets/svg/services/work-light.svg';
import workDark from 'assets/svg/services/work-dark.svg';
import { styles } from './styles';

const props = [
  {
    imgWhite: introductoryLight,
    imgBlack: introductoryDark,
    gridMax: {
      gridColumnStart: 1,
      gridColumnEnd: 5,
      gridRowStart: 1,
      gridRowEnd: 2,
    },
    gridMin: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 2,
      gridRowEnd: 3,
    },
    borderMax: {
      right: true,
      topRight: true,
      bottomRight: true,
    },
    borderMin: {
      right: true,
      topRight: true,
      bottomRight: true,
    },
  },
  {
    imgWhite: imporvingLight,
    imgBlack: imporvingDark,
    gridMax: {
      gridRowStart: 2,
      gridRowEnd: 3,
      gridColumnStart: 3,
      gridColumnEnd: 5,
    },
    gridMin: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 3,
      gridRowEnd: 4,
    },
    borderMin: {
      left: true,
      topLeft: true,
      bottomLeft: true,
    },
  },
  {
    imgWhite: cvLight,
    imgBlack: cvDark,
    gridMax: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 2,
      gridRowEnd: 3,
    },
    gridMin: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 4,
      gridRowEnd: 5,
    },
    borderMax: {
      left: true,
      top: true,
      bottom: true,
    },
    borderMin: {
      right: true,
      topRight: true,
      bottom: true,
    },
  },
  {
    imgWhite: workLight,
    imgBlack: workDark,
    gridMax: {
      gridColumnStart: 1,
      gridColumnEnd: 5,
      gridRowStart: 3,
      gridRowEnd: 4,
    },
    gridMin: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 5,
      gridRowEnd: 6,
    },
  },
];

type Keys = Exclude<keyof typeof translations.services.stages, 'title'>;

export const Stages = () => {
  const { t } = useTranslation();
  const sm = useMediaQuery('(max-width: 800px)');
  return (
    <Box sx={styles.grid}>
      {props.map((el, index) => (
        <Stage
          {...el}
          {...(sm ? el.gridMin : el.gridMax)}
          border={sm ? el.borderMin : el.borderMax}
          key={index}
          title={t(
            translations.services.stages[index.toString() as Keys].title
          )}
          description={t(
            translations.services.stages[index.toString() as Keys].description
          )}
        />
      ))}
      <Typography sx={styles.gridTitle}>
        {t(translations.services.stages.title)}
      </Typography>
    </Box>
  );
};
