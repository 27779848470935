import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { Card } from './components/card';
import reactLight from 'assets/svg/services/react-light.svg';
import reactDark from 'assets/svg/services/react-dark.svg';
import pythonLight from 'assets/svg/services/python-light.svg';
import pythonDark from 'assets/svg/services/python-dark.svg';
import awsLight from 'assets/svg/services/aws-light.svg';
import awsDark from 'assets/svg/services/aws-dark.svg';
import blockchainLight from 'assets/svg/services/blockchain-light.svg';
import blockchainDark from 'assets/svg/services/blockchain-dark.svg';
import nodejsLight from 'assets/svg/services/nodejs-light.svg';
import nodejsDark from 'assets/svg/services/nodejs-dark.svg';
import sqlLight from 'assets/svg/services/sql-light.svg';
import sqlDark from 'assets/svg/services/sql-dark.svg';
import iosLight from 'assets/svg/services/ios-light.svg';
import iosDark from 'assets/svg/services/ios-dark.svg';
import aiLight from 'assets/svg/services/ai-light.svg';
import aiDark from 'assets/svg/services/ai-dark.svg';
import { styles } from './styles';
import { VioletButton } from 'assets/components/violet-button.styled';
import { Stages } from './components/stages';
import { Anchor } from 'modules/common/enums';

type Keys = keyof typeof translations.services.cards;

const services = [
  {
    color: '#C1B5FF',
    imgWhite: reactLight,
    imgBlack: reactDark,
  },
  {
    color: '#FFC02F',
    imgWhite: nodejsLight,
    imgBlack: nodejsDark,
  },
  {
    color: '#C1B5FF',
    imgWhite: pythonLight,
    imgBlack: pythonDark,
  },
  {
    color: '#FFC02F',
    imgWhite: sqlLight,
    imgBlack: sqlDark,
  },
  {
    color: '#FFB7D7',
    imgWhite: awsLight,
    imgBlack: awsDark,
  },
  {
    color: '#2DCA8F',
    imgWhite: iosLight,
    imgBlack: iosDark,
  },
  {
    color: '#FFB7D7',
    imgWhite: blockchainLight,
    imgBlack: blockchainDark,
  },
  {
    color: '#2DCA8F',
    imgWhite: aiLight,
    imgBlack: aiDark,
  },
];

export const Services = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    const element = document.getElementById(Anchor.CONTACT_US);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const sm = useMediaQuery('(max-width: 800px)');

  return (
    <Stack rowGap={sm ? '80px' : '20dvh'}>
      <Stack>
        <Typography variant="h1" sx={styles.title} id={Anchor.SERVICES}>
          {t(translations.services.title)}
        </Typography>
        {sm && (
          <Typography sx={styles.description}>
            {t(translations.services.description1)}
          </Typography>
        )}
        <Box sx={styles.grid}>
          {!sm && (
            <Typography sx={styles.description}>
              {t(translations.services.description1)}
            </Typography>
          )}
          {services.map(({ color, imgWhite, imgBlack }, index) => (
            <Stack key={imgWhite}>
              <Card
                title={t(
                  translations.services.cards[(index + 1).toString() as Keys]
                    .title
                )}
                description={t(
                  translations.services.cards[(index + 1).toString() as Keys]
                    .description
                )}
                imgWhite={imgWhite}
                imgBlack={imgBlack}
                color={color}
              />
            </Stack>
          ))}
          <Stack rowGap="10px" sx={styles.stack}>
            <Typography sx={styles.description}>
              {t(translations.services.description2)}
            </Typography>
            <VioletButton onClick={handleClick}>
              {t(translations.services.button)}
            </VioletButton>
          </Stack>
        </Box>
      </Stack>
      <Stages />
    </Stack>
  );
};
