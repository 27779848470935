import { Stack, Typography } from '@mui/material';
import { styles } from './styles';

export type CaseProps = {
  img: string;
  title: string;
  description: string;
  color: string;
};

export const Case = ({ title, description, color, img }: CaseProps) => (
  <Stack
    sx={{ border: `2px solid ${color}`, padding: '20px', borderRadius: '8px' }}
    rowGap="10px"
    alignItems="flex-start"
  >
    <img src={img} alt="" style={{ height: '50px' }} />\{' '}
    <Typography sx={styles.description}>{description}</Typography>
    <Typography sx={styles.title}>{title}</Typography>
  </Stack>
);
