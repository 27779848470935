import { theme } from 'theme';

export const styles = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    gap: '100px',
    overflow: 'hidden',
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: theme.colors.black,
    },
  },
  container: {
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '100px',
    padding: '0 60px',

    [theme.breakpoints.md]: {
      padding: '10px 15px',
    },
  },
};
