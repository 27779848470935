import { Box, Button, Modal, useMediaQuery } from '@mui/material';

import { LogoIcon } from 'assets/components/logo.icon';
import { translations } from 'translations/translations';

import { styles } from './styles';
import { BurgerIcon } from 'assets/components/burger.icon';
import { useEffect, useRef, useState } from 'react';
import { Navbar } from './components/header.navbar';
import { useColorScheme } from 'hooks/use-color-scheme';

export const Header = () => {
  const sm = useMediaQuery('@media (max-width: 860px)');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const colorScheme = useColorScheme();

  const menuItems = Object.keys(translations.header.menu);

  const handleOpenMenu = () => {
    setIsModalOpen(true);
  };

  const handleClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      setIsModalOpen(false);
    }
  };

  const [show, setShow] = useState(true);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop.current) {
        setShow(false);
      } else {
        setShow(true);
      }

      lastScrollTop.current = scrollTop;
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box sx={styles.root} top={show ? 0 : '-100px'}>
      <Box sx={styles.flex}>
        <Box sx={styles.logo}>
          <LogoIcon colorScheme={colorScheme} />
        </Box>
        {sm ? (
          <Button onClick={handleOpenMenu} sx={styles.burger}>
            <BurgerIcon colorScheme={colorScheme} />
          </Button>
        ) : (
          <Navbar menuItems={menuItems} styles={styles.menu} />
        )}
        <Modal open={isModalOpen} onClose={handleClose}>
          <Navbar
            isMobile
            menuItems={menuItems}
            styles={styles.menuModal as React.CSSProperties}
            closeModal={() => setIsModalOpen(false)}
          />
        </Modal>
      </Box>
    </Box>
  );
};
