import { ContactUs } from 'modules/contact-us/contact-us.component';
import { Header } from 'modules/header';
import { WhoAreWe } from 'modules/who-are-we';
import { Footer } from 'modules/footer';
import { styles } from 'styles';
import { Advantages } from 'modules/advantages';
import { Box } from '@mui/material';
import { Services } from 'modules/services';
import { Cases } from 'modules/cases';
// import { Team } from 'modules/team';

function App() {
  return (
    <Box sx={styles.app}>
      <Header />
      <Box sx={styles.container}>
        <WhoAreWe />
        <Advantages />
        <Services />
        {/* <Team /> */}
        <Cases />
        <ContactUs />
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
