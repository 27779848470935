export const styles = {
  title: {
    fontSize: 'clamp(1.2rem, 1.2vw, 2rem)',
    textAlign: 'center',
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
  },
  description: {
    fontSize: 'clamp(1rem, 1.1vw, 1.2rem)',
    textAlign: 'center',
    whiteSpace: 'balance',
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
  },
  img: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    zIndex: 1,
    transform: 'translate(-50%, -50%)',
  },
  subtitle: {
    fontSize: 'clamp(1rem, 1.1vw, 1.2rem)',
    textAlign: 'center',
    whiteSpace: 'balance',
    width: '30vw',
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
    '@media (max-width: 800px)': {
      width: '100%',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    height: 'fit-content',
    alignItems: 'stretch',
    '@media (max-width: 800px)': {
      gridTemplateColumns: '1fr',

      '& div:nth-of-type(4)': {
        paddingBottom: '0 !important',
      },
    },
  },
  stack: {
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '50px',
    paddingBottom: '100px',
    boxSizing: 'border-box',

    '&:last-child': {
      paddingBottom: '0',
    },
  },
  gridTitle: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    fontSize: 'clamp(1.2rem, 1.2vw, 2rem)',
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
    '@media (max-width: 800px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '50px 0',
    },
  },
};
