export const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
    height: 'fit-content',
    gap: '70px 25px',
    alignItems: 'stretch',
    '@media (max-width: 800px)': {
      gridTemplateColumns: '1fr',
      gap: '20px 0',
    },

    '&>div:nth-of-type(odd)': {
      '@media (max-width: 800px)': {
        paddingLeft: '40px',
      },
    },
    '&>div:nth-of-type(even)': {
      '@media (max-width: 800px)': {
        paddingRight: '40px',
      },
    },
    '&>div:last-child': {
      '@media (max-width: 800px)': {
        padding: '0',
      },
    },
  },
  title: {
    fontSize: 'clamp(2rem, 5vw, 6rem)',
    textAlign: 'center',
    '@media (max-width: 800px)': {
      marginBottom: '0',
    },
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
  },
  description: {
    fontSize: 'clamp(1.2rem, 1.2vw, 1.5rem)',
    textAlign: 'start',
    padding: '20px',
    gridColumnStart: 1,
    gridColumnEnd: 3,
    '@media (max-width: 800px)': {
      gridColumnStart: 1,
      gridColumnEnd: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
  },
  stack: {
    gridColumnStart: -3,
    gridColumnEnd: -1,
    justifyContent: 'flex-end',
    '@media (max-width: 800px)': {
      display: 'none',
    },
    button: {
      width: '50%',
    },
  },
};
