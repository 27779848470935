import { Button } from '@mui/material';
import styled from 'styled-components';
import { theme } from 'theme';

export const VioletButton = styled(Button)`
  background-color: ${theme.colors.violet} !important;
  color: ${theme.colors.dark} !important;
  font-size: clamp(13.5px, 1vw, ${theme.fontSizes.default}) !important;
  text-transform: none !important;
  border-radius: 8px !important;
  padding: 5px 25px !important;

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.colors.lightViolet} !important;
    }
  }

  @media (hover: none) {
    &:active {
      background-color: ${theme.colors.lightViolet} !important;
    }
  }
`;
