export const styles = {
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    margin: 'auto',
    '@media (max-width: 600px)': {
      alignItems: 'center',
    },
    'img': {
      width: '100%',
    }
  },
  title: {
    fontSize: 'clamp(2rem, 5vw, 6rem)',
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    }
  },
  subtitle: {
    display: 'flex',
    textAlign: 'center',
    fontSize: 'clamp(14px, 2.5vw, 1.7rem)',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70dvw',
    '@media (prefers-color-scheme: dark)': {
      color: 'white',
    },
    '@media (max-width: 900px)': {
      width: '100%',
    }
  },
  buttons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  watch: {
    color: '#c2c2c2',
  }
};
