import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
// import { TopBorder } from 'assets/components/top-border';
import { Card } from './components/card';
import employment from 'assets/svg/advantage/employment.svg';
import mentoring from 'assets/svg/advantage/mentoring.svg';
import pumpSkins from 'assets/svg/advantage/pumping-skins.svg';
import support from 'assets/svg/advantage/support-from-experts.svg';
import development from 'assets/svg/advantage/development-skills.svg';
import logoBlack from 'assets/svg/advantage/logo-black.svg';
import logoWhite from 'assets/svg/advantage/logo-white.svg';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { useColorScheme } from 'hooks/use-color-scheme';
import { Anchor } from 'modules/common/enums';

type Keys = Exclude<keyof (typeof translations)['advantages'], 'title'>;

const cardsProps = [
  {
    color: '#2DCA8F',
    img: employment,
    max: {
      gridRowStart: 1,
      gridColumnStart: 2,
      gridRowEnd: 3,
      gridColumnEnd: 6,
    },
    min: {
      gridRowStart: 1,
      gridColumnStart: 1,
      gridRowEnd: 2,
      gridColumnEnd: 2,
    },
  },
  {
    color: '#FFC02F',
    img: mentoring,
    max: {
      gridRowStart: 3,
      gridColumnStart: 1,
      gridRowEnd: 5,
      gridColumnEnd: 5,
    },
    min: {
      gridRowStart: 2,
      gridColumnStart: 1,
      gridRowEnd: 3,
      gridColumnEnd: 2,
    },
  },
  {
    color: '#FFB7D7',
    img: pumpSkins,
    max: {
      gridRowStart: 1,
      gridColumnStart: -2,
      gridRowEnd: 3,
      gridColumnEnd: -6,
    },
    min: {
      gridRowStart: 3,
      gridColumnStart: 1,
      gridRowEnd: 4,
      gridColumnEnd: 2,
    },
  },
  {
    color: '#2DCA8F',
    img: support,
    max: {
      gridRowStart: 3,
      gridColumnStart: -1,
      gridRowEnd: 5,
      gridColumnEnd: -5,
    },
    min: {
      gridRowStart: 4,
      gridColumnStart: 1,
      gridRowEnd: 5,
      gridColumnEnd: 2,
    },
  },
  {
    color: '#C1B5FF',
    img: development,
    max: {
      gridRowStart: 4,
      gridColumnStart: 6,
      gridRowEnd: 6,
      gridColumnEnd: 10,
    },
    min: {
      gridRowStart: 5,
      gridColumnStart: 1,
      gridRowEnd: 6,
      gridColumnEnd: 2,
    },
  },
];

export const Advantages = () => {
  const { t } = useTranslation();
  const colorScheme = useColorScheme();
  const sm = useMediaQuery('(max-width: 800px)');

  return (
    <Stack sx={{ position: 'relative' }} rowGap="30px">
      <Typography sx={styles.title} id={Anchor.ADVANTAGES}>
        {t(translations.advantages.title)}
      </Typography>
      {/* <TopBorder /> */}
      <Box sx={styles.grid}>
        {cardsProps.map(({ color, img, max, min }, index) => (
          <Card
            key={img}
            {...(!sm ? max : min)}
            color={color}
            description={t(
              translations.advantages[(index + 1).toString() as Keys]
                .description
            )}
            title={t(
              translations.advantages[(index + 1).toString() as Keys].title
            )}
            img={img}
          />
        ))}
        {!sm && (
          <Box sx={styles.img}>
            <img
              src={colorScheme ? logoBlack : logoWhite}
              alt="logo"
              style={{ width: '100%' }}
            />
          </Box>
        )}
      </Box>
    </Stack>
  );
};
