import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import { LogoIcon } from 'assets/components/logo.icon';
import { styles } from './styles';
import { translations } from 'translations/translations';
import { useTranslation } from 'react-i18next';
import { config } from 'config';
import { theme } from 'theme';
import { LinkedinIcon } from 'assets/components/linkedin.icon';
import { GithubIcon } from 'assets/components/github.icon';
import { UpworkIcon } from 'assets/components/upwork.icon';
import { AnchorButton } from 'assets/components/anchor.button';

type TranslationKey = keyof typeof translations.footer.menu;

export const Footer = () => {
  const { t } = useTranslation();
  const md = useMediaQuery(theme.media.md);
  const menuItems = Object.keys(translations.footer.menu);

  const handleClick = (id: string) => () => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offset = 100;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box sx={styles.root}>
      <Box>
        <Box sx={styles.logo}>
          <LogoIcon type="dark" />
        </Box>
        {!md && (
          <Typography sx={styles.rigths}>
            {t(translations.footer.rights)}
          </Typography>
        )}
      </Box>

      <Box sx={styles.menu}>
        {menuItems.map((item, index) => (
          <AnchorButton
            key={index}
            variant="white"
            onClick={handleClick(item.split(' ').join('').toLowerCase())}
            text={t(translations.footer.menu[item as TranslationKey])}
          />
        ))}
      </Box>

      <Box sx={styles.iconsContainer}>
        <Box sx={styles.iconsBox}>
          <LinkedinIcon />
          <GithubIcon />
          <UpworkIcon />
        </Box>
        <Link style={styles.email} href={`mailto:${config.email}`}>
          {config.email}
        </Link>
        {!md && (
          <Typography sx={styles.privacyPolicy}>
            {t(translations.footer.privacyPolicy)}
          </Typography>
        )}
      </Box>

      {md && (
        <Box sx={styles.mobileRights}>
          <Typography sx={styles.rigths}>
            {t(translations.footer.rights)}
          </Typography>
          <Typography sx={styles.privacyPolicy}>
            {t(translations.footer.privacyPolicy)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
