import { theme } from 'theme';

export const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    width: '100%',
    boxSizing: 'border-box',
    position: 'fixed',
    zIndex: 2,
    transition: '0.5s',
  },
  flex: {
    borderRadius: '12px',
    border: '1px solid #fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

    '@media (prefers-color-scheme: light)': {
      border: '1px solid #000',
      backgroundColor: '#fff',
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    },

    '@media (prefers-color-scheme: dark)': {
      backgroundColor: '#000',
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    },
  },
  menu: {
    display: 'flex',
    gap: '20px',
  },
  menuModal: {
    backgroundColor: theme.colors.violet,
    color: theme.colors.dark,
    fontSize: theme.fontSizes.default,
    textTransform: 'none',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    padding: '20px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'absolute',
    borderRadius: '16px 0 16px 16px',
    top: '20px',
    right: '20px',
  },
  button: {
    backgroundColor: theme.colors.violet,
    color: theme.colors.dark,
    fontSize: theme.fontSizes.default,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.violet,
    },
  },
  logo: {
    padding: '6px 12px',
    width: '200px',
  },
  burger: {
    borderRadius: '16px',
    '@media (hover: none)': {
      '@media (prefers-color-scheme: light)': {
        '&:active': {
          backgroundColor: '#fff',
        },
      },
      '&:active': {
        backgroundColor: theme.colors.darker,
      },
    },
    '@media (hover: hover)': {
      '@media (prefers-color-scheme: light)': {
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
      '&:hover': {
        backgroundColor: theme.colors.darker,
      },
    },
  },
};
