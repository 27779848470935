import { BaseTextFieldProps, Box, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import { styles } from './styles';

export interface Props extends BaseTextFieldProps {
  list: JSX.Element[];
}

export const Slider = ({ list }: Props) => {
  const respIsMobile = useMediaQuery('@media (max-width: 800px)');
  return respIsMobile ? (
    <Box>
      <Box sx={styles.sliderBlock}>
        <Box sx={styles.slider}>
          <Swiper
            slidesPerView={1}
            spaceBetween={22}
            pagination={respIsMobile}
            modules={[Pagination]}
          >
            {list.map((item, index) => (
              <SwiperSlide key={index} style={styles.slide}>
                <Box sx={styles.slideBox}>{item}</Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={styles.box}>{list}</Box>
  );
};
