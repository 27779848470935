export type BurgerIconProps = {
  colorScheme?: boolean;
};

export const BurgerIcon = ({ colorScheme = false }: BurgerIconProps) =>
  !colorScheme ? (
    <svg
      width="42"
      height="48"
      viewBox="0 0 42 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3606 17.5879C40.0187 17.7856 39.8077 18.154 39.8077 18.5529V33.3259C39.8077 34.326 39.2799 35.2497 38.4241 35.7474L22.3701 45.0835C21.5215 45.577 20.4783 45.577 19.6296 45.0838L3.56596 35.7473C2.70985 35.2497 2.18182 34.3258 2.18182 33.3255V14.6737C2.18182 13.6738 2.70941 12.7502 3.56496 12.2525L19.6288 2.90679C20.4779 2.41279 21.5219 2.41285 22.371 2.90696L34.9144 10.2066C35.2489 10.4012 35.6603 10.4014 35.9949 10.2069C36.7221 9.78422 36.7224 8.72101 35.9953 8.298L22.3709 0.370534C21.5218 -0.123464 20.478 -0.123514 19.6289 0.370401L1.38324 10.9844C0.527633 11.4821 0 12.4057 0 13.4056V34.5943C0 35.5942 0.527634 36.5178 1.38325 37.0156L19.6291 47.6296C20.478 48.1235 21.5218 48.1235 22.3708 47.6296L40.6168 37.0156C41.4724 36.5178 42 35.5942 42 34.5943V18.5529C42 17.7001 41.0913 17.1652 40.3606 17.5879Z"
        fill="#C1B5FF"
      />
      <path
        d="M38.2757 15.4053C39.2647 15.9815 40.5305 15.6377 41.1028 14.6374C41.6751 13.6371 41.3373 12.3591 40.3482 11.7829C39.3592 11.2067 38.0934 11.5505 37.5211 12.5508C36.9488 13.551 37.2866 14.8291 38.2757 15.4053Z"
        fill="#151521"
      />
      <path
        d="M10 18H32"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 24H24"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 30H32"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      width="42"
      height="48"
      viewBox="0 0 42 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3606 17.5879C40.0187 17.7856 39.8077 18.154 39.8077 18.5529V33.3259C39.8077 34.326 39.2799 35.2497 38.4241 35.7474L22.3701 45.0835C21.5215 45.577 20.4783 45.577 19.6296 45.0838L3.56596 35.7473C2.70985 35.2497 2.18182 34.3258 2.18182 33.3255V14.6737C2.18182 13.6738 2.70941 12.7502 3.56496 12.2525L19.6288 2.90679C20.4779 2.41279 21.5219 2.41285 22.371 2.90696L34.9144 10.2066C35.2489 10.4012 35.6603 10.4014 35.9949 10.2069C36.7221 9.78422 36.7224 8.72101 35.9953 8.298L22.3709 0.370534C21.5218 -0.123464 20.478 -0.123514 19.6289 0.370401L1.38324 10.9844C0.527633 11.4821 0 12.4057 0 13.4056V34.5943C0 35.5942 0.527634 36.5178 1.38325 37.0156L19.6291 47.6296C20.478 48.1235 21.5218 48.1235 22.3708 47.6296L40.6168 37.0156C41.4724 36.5178 42 35.5942 42 34.5943V18.5529C42 17.7001 41.0913 17.1652 40.3606 17.5879Z"
        fill="#C1B5FF"
      />
      <path
        d="M38.2757 15.4053C39.2647 15.9815 40.5305 15.6377 41.1028 14.6374C41.6751 13.6371 41.3373 12.3591 40.3482 11.7829C39.3592 11.2067 38.0934 11.5505 37.5211 12.5508C36.9488 13.551 37.2866 14.8291 38.2757 15.4053Z"
        fill="white"
      />
      <path
        d="M10 18H32"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 24H24"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 30H32"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
