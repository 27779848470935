export const styles = {
    title: {
        fontSize: 'clamp(1rem, 1.1vw, 1.5rem)',
        '@media (prefers-color-scheme: dark)': {
            color: 'white',
        }
    },
    description: {
        fontSize: 'clamp(0.9rem, 1vw, 1.3rem)',
        '@media (prefers-color-scheme: dark)': {
            color: 'white',
        }
    }     
}