interface LogoProps {
  type?: 'dark' | 'light';
  colorScheme?: boolean;
}

export const LogoIcon = ({ colorScheme = true, type = 'light' }: LogoProps) => {
  const isDark = type === 'dark';

  const color = isDark ? '#C1B5FF' : '#151521';
  const width = isDark ? '54' : '42';
  const height = isDark ? '62' : '48';
  return !colorScheme ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3606 17.5879C40.0187 17.7856 39.8077 18.154 39.8077 18.5529V33.3259C39.8077 34.326 39.2799 35.2497 38.4241 35.7474L22.3701 45.0835C21.5215 45.577 20.4783 45.577 19.6296 45.0838L3.56596 35.7473C2.70985 35.2497 2.18182 34.3258 2.18182 33.3255V14.6737C2.18182 13.6738 2.70941 12.7502 3.56496 12.2525L19.6288 2.90679C20.4779 2.41279 21.5219 2.41285 22.371 2.90696L34.9144 10.2066C35.2489 10.4012 35.6603 10.4014 35.9949 10.2069C36.7221 9.78422 36.7224 8.72101 35.9953 8.298L22.3709 0.370534C21.5218 -0.123464 20.478 -0.123514 19.6289 0.370401L1.38324 10.9844C0.527633 11.4821 0 12.4057 0 13.4056V34.5943C0 35.5942 0.527634 36.5178 1.38325 37.0156L19.6291 47.6296C20.478 48.1235 21.5218 48.1235 22.3708 47.6296L40.6168 37.0156C41.4724 36.5178 42 35.5942 42 34.5943V18.5529C42 17.7001 41.0913 17.1652 40.3606 17.5879Z"
        fill="#C1B5FF"
      />
      <path
        d="M15.4986 33.7708C15.4986 34.5417 14.8821 35.1666 14.1216 35.1666H13.7705C13.0099 35.1666 12.3934 34.5417 12.3934 33.7708V25.8248C12.3934 25.0539 13.0099 24.429 13.7705 24.429H22.744C23.7233 24.4178 24.6589 24.0094 25.3485 23.2923C26.0381 22.5753 26.4262 21.607 26.4288 20.5971C26.4344 20.0978 26.341 19.6024 26.1541 19.1418C25.967 18.6809 25.6908 18.2646 25.3419 17.9181C25.0044 17.5604 24.6004 17.2768 24.154 17.0842C23.7076 16.8915 23.2279 16.794 22.744 16.7972H13.7705C13.0099 16.7972 12.3934 16.1723 12.3934 15.4014V14.927C12.3934 14.1561 13.0099 13.5312 13.7705 13.5312H22.7543C24.5682 13.5424 26.3048 14.2905 27.5875 15.6131C28.8701 16.9358 29.5955 18.7266 29.6063 20.5971C29.6174 22.029 29.1941 23.4284 28.3954 24.5997C27.6604 25.7203 26.6245 26.5956 25.4145 27.1186C24.2162 27.5105 22.9659 27.7051 21.7089 27.6951H16.8757C16.1151 27.6951 15.4986 28.32 15.4986 29.0909V33.7708Z"
        fill={color}
      />
      <path
        d="M28.4662 32.3936C28.9775 33.324 28.3139 34.4687 27.2632 34.4687H26.9179C26.4182 34.4687 25.9577 34.1943 25.7147 33.7516L24.4232 31.3992C23.953 30.5427 24.5269 29.467 25.4636 29.2238C26.0907 29.061 26.7701 29.3075 27.0851 29.8807L28.4662 32.3936Z"
        fill={color}
      />
      <path
        d="M38.2757 15.4053C39.2647 15.9815 40.5305 15.6377 41.1028 14.6374C41.6751 13.6371 41.3373 12.3591 40.3482 11.7829C39.3592 11.2067 38.0934 11.5505 37.5211 12.5508C36.9488 13.551 37.2866 14.8291 38.2757 15.4053Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width="43"
      height="49"
      viewBox="0 0 43 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.3215 17.9543C40.9715 18.1562 40.7555 18.5322 40.7555 18.9394V34.0202C40.7555 35.0411 40.2151 35.984 39.3389 36.4921L22.9027 46.0227C22.0339 46.5265 20.9659 46.5266 20.0969 46.023L3.65086 36.492C2.77437 35.9841 2.23377 35.0409 2.23377 34.0197V14.9794C2.23377 13.9586 2.77392 13.0159 3.64984 12.5077L20.0962 2.96735C20.9655 2.46305 22.0343 2.46312 22.9036 2.96752L35.7457 10.4192C36.0882 10.6179 36.5093 10.6181 36.8519 10.4195C37.5965 9.98805 37.5967 8.9027 36.8523 8.47088L22.9035 0.378253C22.0343 -0.126036 20.9655 -0.126087 20.0963 0.378118L1.41617 11.2132C0.540196 11.7213 0 12.6641 0 13.6849V35.315C0 36.3358 0.540196 37.2786 1.41618 37.7867L20.0964 48.6219C20.9656 49.126 22.0343 49.126 22.9034 48.6219L41.5838 37.7867C42.4598 37.2786 43 36.3358 43 35.315V18.9394C43 18.0688 42.0696 17.5228 41.3215 17.9543Z"
        fill="#C1B5FF"
      />
      <path
        d="M15.8676 34.4744C15.8676 35.2613 15.2365 35.8993 14.4578 35.8993H14.0984C13.3197 35.8993 12.6885 35.2613 12.6885 34.4744V26.3628C12.6885 25.5758 13.3197 24.9379 14.0984 24.9379H23.2856C24.2882 24.9265 25.246 24.5096 25.9521 23.7775C26.658 23.0456 27.0554 22.0572 27.0581 21.0262C27.0638 20.5165 26.9681 20.0108 26.7768 19.5405C26.5853 19.0701 26.3025 18.6451 25.9453 18.2914C25.5997 17.9263 25.1861 17.6367 24.7291 17.4401C24.272 17.2434 23.781 17.1438 23.2856 17.1472H14.0984C13.3197 17.1472 12.6885 16.5093 12.6885 15.7223V15.238C12.6885 14.451 13.3197 13.8131 14.0984 13.8131H23.2961C25.1532 13.8246 26.9311 14.5882 28.2444 15.9384C29.5575 17.2887 30.3002 19.1168 30.3113 21.0262C30.3226 22.4879 29.8892 23.9165 29.0715 25.1122C28.319 26.2562 27.2584 27.1497 26.0196 27.6836C24.7928 28.0837 23.5127 28.2822 22.2258 28.2721H17.2775C16.4988 28.2721 15.8676 28.91 15.8676 29.697V34.4744Z"
        fill="white"
      />
      <path
        d="M29.1439 33.0685C29.6674 34.0182 28.988 35.1868 27.9123 35.1868H27.5588C27.0472 35.1868 26.5757 34.9067 26.3269 34.4548L25.0047 32.0533C24.5233 31.179 25.1109 30.0809 26.0699 29.8326C26.7119 29.6665 27.4075 29.9181 27.73 30.5032L29.1439 33.0685Z"
        fill="white"
      />
      <path
        d="M39.187 15.7262C40.1996 16.3144 41.4955 15.9635 42.0814 14.9424C42.6674 13.9212 42.3215 12.6166 41.3089 12.0284C40.2963 11.4402 39.0004 11.7911 38.4145 12.8122C37.8285 13.8334 38.1744 15.138 39.187 15.7262Z"
        fill="white"
      />
    </svg>
  );
};
