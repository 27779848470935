import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { styles } from './styles';
import video from 'assets/svg/who-are-we/video.svg';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations/translations';
import { VioletButton } from 'assets/components/violet-button.styled';
import { OutlinedButton } from 'assets/components/outlined-button.styled';
import { theme } from 'theme';
import { Anchor } from 'modules/common/enums/anchor.enums';

export const WhoAreWe = () => {
  const { t } = useTranslation();
  const sm = useMediaQuery(theme.media.sm);

  const handleClick = () => {
    const element = document.getElementById(Anchor.CONTACT_US);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offset = 100;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Stack>
      <Box sx={styles.container}>
        <Typography sx={styles.title} id={Anchor.WHO_ARE_WE}>
          {t(translations.whoAreWe.title)}
        </Typography>
        <Typography sx={styles.subtitle}>
          {t(translations.whoAreWe.subtitle)}
        </Typography>
        {/* TODO: video */}
        <img src={video} alt="" />
        {sm ? (
          <Stack alignItems="center" gap="16px">
            <Typography sx={styles.watch}>
              {t(translations.whoAreWe.watch)}
            </Typography>
            <OutlinedButton sx={{ marginBottom: '50px', height: '40px' }}>
              {t(translations.whoAreWe.buttons.moreAboutRangein)}
            </OutlinedButton>
          </Stack>
        ) : null}
        <Typography sx={styles.subtitle}>
          {t(translations.whoAreWe.p[1])}
        </Typography>
        <Typography sx={styles.subtitle}>
          {t(translations.whoAreWe.p[2])}
        </Typography>
        <Box sx={styles.buttons}>
          <VioletButton sx={{ height: '40px' }} onClick={handleClick}>
            {t(translations.whoAreWe.buttons.submitAnApplication)}
          </VioletButton>
          {!sm ? (
            <OutlinedButton sx={{ height: '40px' }}>
              {t(translations.whoAreWe.buttons.moreAboutRangein)}
            </OutlinedButton>
          ) : null}
        </Box>
      </Box>
    </Stack>
  );
};
