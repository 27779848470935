import { Button } from '@mui/material';
import styled from 'styled-components';
import { theme } from 'theme';

export const OutlinedButton = styled(Button)`
  color: ${theme.colors.dark} !important;
  font-size: clamp(13.5px, 1vw, ${theme.fontSizes.default}) !important;
  text-transform: none !important;
  border-radius: 8px !important;
  padding: 5px 25px !important;
  border: 2px solid ${theme.colors.lightBlack} !important;
  background-color: ${theme.colors.white} !important;
  box-sizing: border-box;

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.colors.lightBlack} !important;
      color: ${theme.colors.white} !important;
    }
  }

  @media (hover: none) {
    &:active {
      background-color: ${theme.colors.lightBlack} !important;
      color: ${theme.colors.white} !important;
    }
  }

  @media (prefers-color-scheme: dark) {
    border: 2px solid ${theme.colors.white} !important;
    background-color: ${theme.colors.black} !important;
    color: ${theme.colors.white} !important;

    @media (hover: hover) {
      &:hover {
        background-color: ${theme.colors.white} !important;
        color: ${theme.colors.black} !important;
      }
    }
  }
`;
