export const styles = {
    title: {
        textAlign: 'start',
        fontSize: 'clamp(1.2rem, 1.3vw, 1.5rem)',

        '@media (prefers-color-scheme: dark)': {
            color: '#fff',
        }
    },
    description: {
        textAlign: 'start',
        fontSize: 'clamp(1rem, 1.1vw, 1.2rem)',
        '@media (prefers-color-scheme: dark)': {
            color: '#fff',
        }
    },
};
  